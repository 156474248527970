import React, { Component } from "react";
import { connect } from "react-redux";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import "../HomePageSiteOwner/HomePage.css";
import Sidebar from "../SidebarAnnualReportSiteOwner/Sidebar.js";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import TranslationFile from "../../Translation/Translation.json";

const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails.sessionDetails.values,
    login: state.login.login.values,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
    prices: state.prices.prices,
    currentPageName: state.currentPageName.currentPageName,
    payment: state.payment.payment,
  };
};

let userSelectedLanguage;
let loginDetails = "";
class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountingCompanyUsersCount: 0,
      privateUsersCount: 0,
      totalReportsCreatedCount: 0,
      totalReportsSubmittedCount: 0,
      totalSalesAmount: 0,
      totalUsersCount: 0,
      sideBarStatus: true,
      showAppsHomePage: false,
      seriesLine: [
        {
          name: "Users",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
      ],
      optionsLine: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Users by Month",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
          ],
        },
      },
      seriesSubmitted: [
        {
          name: "Reports",
          type: "column",
          data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
        },
      ],
      optionsSubmitted: {
        chart: {
          height: 350,
          type: "line",
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: "Total Number of Reports Submitted",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: [
          "01 Jan 2001",
          "02 Jan 2001",
          "03 Jan 2001",
          "04 Jan 2001",
          "05 Jan 2001",
          "06 Jan 2001",
          "07 Jan 2001",
          "08 Jan 2001",
          "09 Jan 2001",
          "10 Jan 2001",
          "11 Jan 2001",
          "12 Jan 2001",
        ],
        xaxis: {
          type: "datetime",
        },
        yaxis: [
          {
            title: {
              text: "Reports",
            },
          },
        ],
        colors: [
          function ({ value, seriesIndex, w }) {
            if (value < 400) {
              return "#FF0000";
            } else {
              return "#0174BE";
            }
          },
        ],
        colors: [
          function ({ value, seriesIndex, w }) {
            if (value <= 400) {
              return "#FF0000";
            } else {
              return "#0174BE";
            }
          },
        ],
      },
    };
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showAppsHomePage: true });

      axios.get("/siteOwner/getInfoForSiteOwner").then((response) => {
        this.setState({
          accountingCompanyUsersCount:
            response.data.accountingCompanyUsersCount,
          privateUsersCount: response.data.privateUsersCount,
          totalReportsCreatedCount: response.data.totalReportsCreatedCount,
          totalReportsSubmittedCount: response.data.totalReportsSubmittedCount,
          totalSalesAmount: response.data.totalSalesAmount,
          totalUsersCount: response.data.totalUsersCount,
        });
      });
    } else {
      this.setState({ showAppsHomePage: false });
      this.props.history.push("/siteOwner");
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };

  render() {
    const {
      accountingCompanyUsersCount,
      privateUsersCount,
      totalReportsCreatedCount,
      totalReportsSubmittedCount,
      totalSalesAmount,
      totalUsersCount,
      sideBarStatus,
    } = this.state;
    return (
      <div className="so_homepage">
        {this.state.showAppsHomePage && (
          <div>
            <div>
              <HeaderNavBar />
            </div>
            <div className="HomePage_Sidebar">
              <Sidebar
                children={"HomePage"}
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <div
                      className={
                        sideBarStatus
                          ? "so-main-div-DataTable"
                          : "so-main-div-DataTableClose"
                      }
                    // className="so-main-div"
                    >
                      <div className="so-whole-div">
                        <div className="so-sub-div">
                          <div className="so-sub-span">
                            {TranslationFile.totalUsers[userSelectedLanguage]}
                            {/* Total Users */}
                            <br></br>
                            {totalUsersCount}
                          </div>
                        </div>
                        <div className="so-sub-div">
                          <div className="so-sub-span">
                            {TranslationFile.totalSales[userSelectedLanguage]}
                            {/* Total sales */}
                            <br></br>
                            {totalSalesAmount} SEK
                          </div>
                        </div>
                        <div className="so-sub-div">
                          <div className="so-sub-span">
                            {
                              TranslationFile.totalReportCreated[
                              userSelectedLanguage
                              ]
                            }
                            {/* Total Report Created */}
                            <br></br>
                            {totalReportsCreatedCount}
                          </div>
                        </div>
                        <div className="so-sub-div">
                          <div className="so-sub-span">
                            {
                              TranslationFile.totalReportSubmitted[
                              userSelectedLanguage
                              ]
                            }
                            {/* Total Report Submitted */}
                            <br></br>
                            {totalReportsSubmittedCount}
                          </div>
                        </div>
                      </div>
                      <div className="so-chart-main-div">
                        <div className="line-so-chart">
                          <div className="inside-div-chart">
                            <ReactApexChart
                              options={this.state.optionsSubmitted}
                              series={this.state.seriesSubmitted}
                              type="line"
                              height={400}
                              width={580}
                            />
                          </div>
                        </div>
                        <div className="line-so-chart">
                          <div className="inside-div-chart">
                            <ReactApexChart
                              options={this.state.optionsLine}
                              series={this.state.seriesLine}
                              type="line"
                              height={400}
                              width={580}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, null)(HomePage);
