import React, { Component } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import "../TemplateAnnualReportSiteOwner/Template.css";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { setUserSessionAuthKey } from "../../../Component/commonUtils.js";
import Sidebar from "../SidebarAnnualReportSiteOwner/Sidebar.js";
import HeaderNavBar from "../../HeaderNavBar/HeaderNavBar.js";
import TranslationFile from "../../Translation/Translation.json";
import config from "../../../Configuration/Config.js";

const statementType = [
  { name: "Income Statement", code: 1 },
  { name: "Balance Sheet", code: 2 },
  { name: "Notes", code: 3 },
  { name: "Management Statement", code: 4 },
];

let loginDetails = "";
let userSelectedLanguage;

const statementSubType = [
  { name: "Accounting Principle", code: 1 },
  { name: "Income Statement", code: 2 },
  { name: "Balance Statement", code: 3 },
  { name: "Heading1", code: 4 },
  { name: "Heading2", code: 5 },
  { name: "Heading3", code: 6 },
];

const lineType = [
  { name: "Header", code: 1 },
  { name: "Line", code: 2 },
  { name: "Line Sum", code: 3 },
  { name: "Final Sum", code: 4 },
];

const lineLevel = [
  { name: 1, code: 1 },
  { name: 2, code: 2 },
  { name: 3, code: 3 },
  { name: 4, code: 4 },
];

let userSessionAuthKeyReturnValue = setUserSessionAuthKey();
const mapStateToProps = (state) => {
  return {
    annualReportType: state.annualReportType.annualReportType.values,
    companyInformation: state.companyInformation.companyInformation.values,
    financialYear: state.financialYear.financialYear,
    sessionDetails: state.sessionDetails,
    sideBarReducer: state.sideBarReducer.sideBarReducer,
  };
};

userSelectedLanguage = localStorage.getItem("selectedLanguage");
let templateNames = [
  { title: TranslationFile.IncomeStatementBalanceSheet[userSelectedLanguage] },
  { title: TranslationFile.NotesManagementStatement[userSelectedLanguage] },
  { title: TranslationFile.AnnualReportPackages[userSelectedLanguage] },
  // { title: TranslationFile.SRUPackages[userSelectedLanguage] },
];

let selectedFile = "";
let fromDateValue = "",
  toDateValue = "";

class Template extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      activeIndex: 0,
      visible: false,
      choosenFile: "",
      totalSize: 0,
      dummy: "",
      showProceedButton: false,
      templateLinesGrid: [],
      templateLinesGridForPackage: [],
      templateLinesGridForNotes: [],
      fromDate: "",
      toDate: "",
      sideBarStatus: true,
      showTemplatePage: false,
    };
    this.downloadTemplate = this.downloadTemplate.bind(this);
  }

  componentWillMount() {
    userSelectedLanguage = localStorage.getItem("selectedLanguage");
    loginDetails = localStorage.getItem("RBLSiteOwner");
    loginDetails = JSON.parse(loginDetails);
    if (loginDetails != null && loginDetails["email"]) {
      this.setState({ showTemplatePage: true });

      userSessionAuthKeyReturnValue = setUserSessionAuthKey();
      if (userSessionAuthKeyReturnValue) {
        if (this.state.activeIndex == 0) {
          axios
            .get(
              "/siteOwner/getAnnualReportTemplateLines",
              userSessionAuthKeyReturnValue
            )
            .then((resp) => {
              if (resp.data.length > 0) {
                this.setState({
                  templateLinesGrid: resp.data,
                });
              }
            });
        } else if (this.state.activeIndex == 1) {
          axios
            .get(
              "/siteOwner/getAnnualReportTemplateDetails",
              userSessionAuthKeyReturnValue
            )
            .then((resp) => {
              if (resp.data.length > 0) {
                this.setState({
                  templateLinesGrid: resp.data,
                });
              }
            });
        } else if (this.state.activeIndex == 2) {
          axios
            .get(
              "/siteOwner/getTemplatePackages",
              userSessionAuthKeyReturnValue
            )
            .then((resp) => {
              if (resp.data.length > 0) {
                this.setState({
                  templateLinesGrid: resp.data,
                });
              }
            });
        } 
        // else {
        //   axios
        //     .get(
        //       "/siteOwner/getTemplatePackages",
        //       userSessionAuthKeyReturnValue
        //     )
        //     .then((resp) => {
        //       if (resp.data.length > 0) {
        //         this.setState({
        //           templateLinesGrid: resp.data,
        //         });
        //       }
        //     });
        // }
      }
    } else {
      this.setState({ showTemplatePage: false });
      this.props.history.push("/siteOwner");
    }
  }

  test(idx) {
    if (userSessionAuthKeyReturnValue) {
      if (idx == 0) {
        var file = selectedFile;
        const url = "/siteOwner/insertAnnualReportTemplateLines?templateName=AnnualReportTemplateLines";
        const formData = new FormData();
        formData.append("file", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            "User-Session-Auth-Key":
              userSessionAuthKeyReturnValue.headers["User-Session-Auth-Key"],
          },
        };
        axios
          .post(url, formData, config, userSessionAuthKeyReturnValue)
          .then((response) => {
            if (response.data == 'Success') {
              axios
                .get(
                  "/siteOwner/getAnnualReportTemplateLines",
                  userSessionAuthKeyReturnValue
                )
                .then((resp) => {
                  if (resp.data.length > 0) {
                    this.setState({
                      templateLinesGrid: resp.data,
                    });
                  }
                });
            }
          });
      } else if (idx == 1) {
        var file = selectedFile;
        const url = "/siteOwner/insertAnnualReportTemplateDetails?templateName=AnnualReportTemplateDetails";
        const formData = new FormData();
        formData.append("file", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            "User-Session-Auth-Key":
              userSessionAuthKeyReturnValue.headers["User-Session-Auth-Key"],
          },
        };

        axios
          .post(url, formData, userSessionAuthKeyReturnValue)
          .then((response) => {
            if (response.data == "Success") {
              axios
                .get(
                  "/siteOwner/getAnnualReportTemplateDetails",
                  userSessionAuthKeyReturnValue
                )
                .then((resp) => {
                  if (resp.data.length > 0) {
                    this.setState({
                      templateLinesGrid: resp.data,
                    });
                  }
                });
            }
          })
          .catch((error) => {
            axios
              .get(
                "/siteOwner/getAnnualReportTemplateDetails",
                userSessionAuthKeyReturnValue
              )
              .then((resp) => {
                if (resp.data.length > 0) {
                  this.setState({
                    templateLinesGrid: resp.data,
                  });
                }
              });
          });
      } else {
        var file = selectedFile;
        const url = "/siteOwner/insertTemplatePackages?templateName=TemplatePackages";
        const formData = new FormData();
        formData.append("file", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            "User-Session-Auth-Key":
              userSessionAuthKeyReturnValue.headers["User-Session-Auth-Key"],
          },
        };
        axios
          .post(url, formData, config, userSessionAuthKeyReturnValue)
          .then((response) => {
            if (response.data == "Success") {
              axios
                .get(
                  "/siteOwner/getTemplatePackages",
                  userSessionAuthKeyReturnValue
                )
                .then((resp) => {
                  if (resp.data.length > 0) {
                    this.setState({
                      templateLinesGrid: resp.data,
                    });
                  }
                })
                .catch((error) => {
                  axios
                    .get(
                      "/siteOwner/getTemplatePackages",
                      userSessionAuthKeyReturnValue
                    )
                    .then((resp) => {
                      if (resp.data.length > 0) {
                        this.setState({
                          templateLinesGrid: resp.data,
                        });
                      }
                    });
                });
            }
          });
      }

      var reader = new FileReader();
      reader.onload = function (e) { };

      this.setState({ visible: false, showProceedButton: false });
    }
  }

  selectFile = (selectedFileCallback) => {
    selectedFile = selectedFileCallback.files[0];

    let selectedFileFormat = selectedFileCallback.files[0].name
      .split(".")
      .pop();

    this.setState({
      showProceedButton: true,
    });

    this.setState({
      dummy: "",
    });
  };

  mapToAccNosEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  sKMappingRangeEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  column1Editor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  customerTypeEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  maxUsageEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  packageNameEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  priceEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  validityPeriodEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  text1Editor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  text2Editor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  text3Editor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  visibleInGridEditor = (options) => {
    return (
      <Checkbox
        inputId="binary"
        checked={options.value}
        onChange={(e) => options.editorCallback(e.checked)}
      />
    );
  };

  validFromDateEditor = (options) => {
    fromDateValue = new Date(options.value);
    return (
      <Calendar
        value={fromDateValue}
        onChange={(e) => (fromDateValue = e.value)}
        dateFormat="yy-mm-dd"
      />
    );
  };

  validToDateEditor = (options) => {
    toDateValue = new Date(options.value);
    return (
      <Calendar
        value={toDateValue}
        onChange={(e) => (toDateValue = e.value)}
        dateFormat="yy-mm-dd"
      />
    );
  };

  onRowEditComplete = (e, i) => {
    let { newData, index, data } = e;
    if (userSessionAuthKeyReturnValue) {
      if (i == 0) {
        let statementTypeObj = {
          name: "",
          code: "",
        };

        let lineTypeObj = {
          name: "",
          code: "",
        };

        if (typeof newData.statementType == "string") {
          statementType.map((i, idx) => {
            if (i.name == newData.statementType) {
              statementTypeObj.code = i.code;
              statementTypeObj.name = i.name;
            }
          });
        }

        if (typeof newData.lineType == "string") {
          lineType.map((i, idx) => {
            if (i.name == newData.lineType) {
              lineTypeObj.code = i.code;
              lineTypeObj.name = i.name;
            }
          });
        }

        let updateTemplateLinesReq = {
          no: newData.no,
          validFromDate: fromDateValue,
          validToDate: newData.validToDate == "" ? "1900-01-01" : toDateValue,
          statementType:
            typeof newData.statementType == "string"
              ? statementTypeObj.code
              : newData.statementType.code,
          lineType:
            typeof newData.lineType == "string"
              ? lineTypeObj.code
              : newData.lineType.code,
          lineLevel: newData.lineLevel,
          sumLineNos: newData.mapToAccNos,
          parentLineNo: newData.parentLineNo,
          lineNo: newData.lineNo,
          accountDescription: newData.accDesc,
          mapToAccountNos: newData.mapToAccNos,
          sKMappingRange: newData.sKMappingRange,
        };

        if (fromDateValue == "Invalid Date") {
          const fromDate = new Date(newData.validFromDate);
          updateTemplateLinesReq.validFromDate = fromDate.getTime();
        } else {
          const fromDate = new Date(fromDateValue);
          updateTemplateLinesReq.validFromDate = fromDate.getTime();
        }

        if (toDateValue == "") {
          // updateTemplateLinesReq.validToDate = 0;
          updateTemplateLinesReq.validToDate = "1900-01-01";
        }

        if (toDateValue == "Invalid Date") {
          if (newData.validToDate == "") {
            // updateTemplateLinesReq.validToDate = 0;
            updateTemplateLinesReq.validToDate = "1900-01-01";
          } else {
            const toDate = new Date(newData.validToDate);
            updateTemplateLinesReq.validToDate = toDate.getTime();
          }
        } else {
          const toDate = new Date(toDateValue);
          updateTemplateLinesReq.validToDate = toDate.getTime();
        }
        // let epochValue = i.createdAtDateTime;
        //       let createdDateValueFormat =
        //         moment(epochValue).format("YYYY-MM-DD");

        updateTemplateLinesReq.validFromDate = moment(
          updateTemplateLinesReq.validFromDate
        ).format("YYYY-MM-DD");
        if (
          updateTemplateLinesReq.validFromDate !== "" &&
          updateTemplateLinesReq.accDesc != ""
        ) {
          axios
            .post(
              "/siteOwner/updateAnnualReportTemplateLines",
              updateTemplateLinesReq,
              userSessionAuthKeyReturnValue
            )
            .then((response) => {
              if (response.data) {
                axios
                  .get(
                    "/siteOwner/getAnnualReportTemplateLines",
                    userSessionAuthKeyReturnValue
                  )
                  .then((resp) => {
                    if (resp.data.length > 0) {
                      this.setState({
                        templateLinesGrid: resp.data,
                      });
                    }
                  });
              }
            });
        }
      } else if (i == 1) {
        let statementTypeObj = {
          name: "",
          code: "",
        };
        let statementSubTypeObj = {
          name: "",
          code: "",
        };

        let lineTypeObj = {
          name: "",
          code: "",
        };

        if (typeof newData.statementType == "string") {
          statementType.map((i, idx) => {
            if (i.name == newData.statementType) {
              statementTypeObj.code = i.code;
              statementTypeObj.name = i.name;
            }
          });
        }

        if (typeof newData.statementSubType == "string") {
          statementSubType.map((i, idx) => {
            if (i.name == newData.statementSubType) {
              statementSubTypeObj.code = i.code;
              statementSubTypeObj.name = i.name;
            }
          });
        }

        if (typeof newData.lineType == "string") {
          lineType.map((i, idx) => {
            if (i.name == newData.lineType) {
              lineTypeObj.code = i.code;
              lineTypeObj.name = i.name;
            }
          });
        }

        let updateTemplateDetailsReq = {
          no: newData.no,
          validFromDate: fromDateValue,
          validToDate: newData.validToDate == "" ? "1900-01-01" : toDateValue,
          statementType:
            typeof newData.statementType == "string"
              ? statementTypeObj.code
              : newData.statementType.code,
          statementSubType:
            typeof newData.statementSubType == "string"
              ? statementSubTypeObj.code
              : newData.statementSubTypeObj.code,
          lineType:
            typeof newData.lineType == "string"
              ? lineTypeObj.code
              : newData.lineType.code,
          lineLevel: newData.lineLevel,
          sumLineNos: newData.mapToAccNos,
          parentLineNo: newData.parentLineNo,
          lineNo: newData.lineNo,
          accountDescription: newData.accDesc,
          mapToAccountNos: newData.mapToAccNos,
          sKMappingRange: newData.sKMappingRange,
          // isFixedAsset: newData.isFixedAsset,
          // isEmployeeAccount: newData.isEmployeeAcc,
        };

        axios
          .post(
            "/siteOwner/updateAnnualReportTemplateDetails",
            updateTemplateDetailsReq,
            userSessionAuthKeyReturnValue
          )
          .then((response) => { });
      } else {
        let updatePackageReq = {
          packageId: newData.packageId,
          customerType: newData.customerType,
          maxUsage: newData.maxUsage,
          packageName: newData.packageName,
          price: newData.price,
          validityPeriod: newData.validityPeriod,
          text1: newData.text1,
          text2: newData.text2,
          text3: newData.text3,
          visibleInGrid: newData.visibleInGrid,
        };

        axios
          .post(
            "/siteOwner/updateTemplatePackages",
            updatePackageReq,
            userSessionAuthKeyReturnValue
          )
          .then((response) => {
            if (response.data) {
              axios
                .get(
                  "/siteOwner/getTemplatePackages",
                  userSessionAuthKeyReturnValue
                )
                .then((resp) => {
                  if (resp.data.length > 0) {
                    this.setState({
                      templateLinesGrid: resp.data,
                    });
                  }
                });
            }
          });
      }
    }
  };

  onRowEditInit(e) { }

  visibleInGridBodyTemplate(e) {
    return <Checkbox checked={e.visibleInGrid}></Checkbox>;
  }

  fromDateString(e) {
    const date = new Date(e.validFromDate);
    let formattedFromDate = moment(date).format("yyy-MM-DD");
    return formattedFromDate;
  }

  toDateString(e) {
    if (e.validToDate != "") {
      const fromDate = new Date(e.validFromDate);
      let formattedFromDate = moment(fromDate).format("yyy-MM-DD");

      const toDate = new Date(e.validToDate);
      let formattedToDate = moment(toDate).format("yyy-MM-DD");

      if (formattedToDate < formattedFromDate) return "";
      else return formattedToDate;
    } else {
      return "";
    }
  }

  isCheckBoxEditor = (options) => {
    return (
      <Checkbox
        inputId="binary"
        checked={options.value}
        onChange={(e) => options.editorCallback(e.checked)}
      />
    );
  };

  isCheckBoxEditorEmp = (options) => {
    return (
      <Checkbox
        inputId="binary"
        checked={options.value}
        onChange={(e) => options.editorCallback(e.checked)}
      />
    );
  };

  statementTypeEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statementType}
        onChange={(e) => options.editorCallback(e.value)}
        optionLabel="name"
        placeholder={options.value}
      />
    );
  };

  statementSubTypeEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statementSubType}
        onChange={(e) => options.editorCallback(e.value)}
        optionLabel="name"
        placeholder={options.value}
      />
    );
  };

  lineTypeEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={lineType}
        onChange={(e) => options.editorCallback(e.value)}
        optionLabel="name"
        placeholder={options.value}
      />
    );
  };

  lineLevelEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={lineLevel}
        onChange={(e) => options.editorCallback(e.value)}
        optionLabel="name"
        placeholder={options.value}
      />
    );
  };

  sumLineNosEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  parentLineNoEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  lineNoEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };


  tabOnChange(e) {
    this.setState({ activeIndex: e.index });
    if (e.index == 0) {
      axios
        .get(
          "/siteOwner/getAnnualReportTemplateLines",
          userSessionAuthKeyReturnValue
        )
        .then((resp) => {
          if (resp.data.length > 0) {
            this.setState({
              templateLinesGrid: resp.data,
            });
          }
        });
    } else if (e.index == 1) {
      axios
        .get(
          "/siteOwner/getAnnualReportTemplateDetails",
          userSessionAuthKeyReturnValue
        )
        .then((resp) => {
          resp.data.forEach((element) => {
            // console.log(
            //   element.mapToAccountNos,
            //   "@@555details",
            //   element.skMappingRange
            // );
          });
          if (resp.data.length > 0) {
            this.setState({
              templateLinesGrid: resp.data,
            });

            resp.data.map((i, idx) => {
              i["accDesc"] = i.column1;
            });
          }
        });
    } else {
      axios
        .get("/siteOwner/getTemplatePackages", userSessionAuthKeyReturnValue)
        .then((resp) => {
          if (resp.data.length > 0) {
            this.setState({
              templateLinesGrid: resp.data,
            });
          }
        });
    }
  }

  sideBarStatus = (e) => {
    this.setState({
      sideBarStatus: e,
    });
  };


  downloadTemplate = async (i) => {

    if (userSessionAuthKeyReturnValue) {
      if (i == 0) {
        if (userSessionAuthKeyReturnValue !== "" && userSessionAuthKeyReturnValue !== null) {
          let localData = localStorage.getItem("RBLSiteOwner");
          let localObject = localData && JSON.parse(localData);

          fetch(config.backend.url + "siteOwner/exportAnnualReportTemplateLines", {
            method: "GET",
            headers: {
              "Content-Type": "application/vnd.ms-excel",
              "User-Session-Auth-Key": localObject["User-Session-Auth-Key"],
            },
          })
            .then(response => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob(); // Convert the response to a Blob
            })
            .then(blob => {
              const url = window.URL.createObjectURL(blob);

              const link = document.createElement("a");
              link.href = url;
              link.download = "Export_IncomeStatement&BalanceSheet_Template.xlsx";
              document.body.appendChild(link);
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch(error => {
              alert(error);
            });
        }
      } else if (i == 1) {
        if (userSessionAuthKeyReturnValue !== "" && userSessionAuthKeyReturnValue !== null) {
          let localData = localStorage.getItem("RBLSiteOwner");
          let localObject = localData && JSON.parse(localData);

          fetch(config.backend.url + "siteOwner/exportAnnualReportTemplateDetails", {
            method: "GET",
            headers: {
              "Content-Type": "application/vnd.ms-excel",
              "User-Session-Auth-Key": localObject["User-Session-Auth-Key"],
            },
          })
            .then(response => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob(); // Convert the response to a Blob
            })
            .then(blob => {
              const url = window.URL.createObjectURL(blob);

              const link = document.createElement("a");
              link.href = url;
              link.download = "Export_Notes&ManagementStatement_Template.xlsx";
              document.body.appendChild(link);
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch(error => {
              alert(error);
            });
        }
      } else {
        if (userSessionAuthKeyReturnValue !== "" && userSessionAuthKeyReturnValue !== null) {
          let localData = localStorage.getItem("RBLSiteOwner");
          let localObject = localData && JSON.parse(localData);

          fetch(config.backend.url + "siteOwner/exportAnnualReportTemplatePackage", {
            method: "GET",
            headers: {
              "Content-Type": "application/vnd.ms-excel",
              "User-Session-Auth-Key": localObject["User-Session-Auth-Key"],
            },
          })
            .then(response => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob(); // Convert the response to a Blob
            })
            .then(blob => {
              const url = window.URL.createObjectURL(blob);

              const link = document.createElement("a");
              link.href = url;
              link.download = "Export_PricePackage_Template.xlsx";
              document.body.appendChild(link);
              link.click();

              // Clean up and remove the link
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);
            })
            .catch(error => {
              alert(error);
            });
        }

      }
    }
  }




  render() {
    const {
      showProceedButton,
      templateLinesGrid,
      templateLinesGridForPackage,
      templateLinesGridForNotes,
      sideBarStatus,
    } = this.state;

    const header = (
      <div className="templates-Header-Tab">
        {TranslationFile.Templates[userSelectedLanguage]}
        {/* Templates */}
      </div>
    );
    return (
      <div className="so-template-outside-div">
        <Toast
          ref={(el) => {
            this.toast = el;
          }}
          position="center"
        ></Toast>
        {this.state.showTemplatePage && (
          <div>
            <div>
              <HeaderNavBar />
            </div>
            <div className="Template_Sidebar">
              <Sidebar
                children={"Template"}
                sideBarStatus={this.sideBarStatus.bind(this)}
              />
            </div>
            <ConfirmPopup />
            <div>
              <div
                className={
                  sideBarStatus
                    ? "SOTemplate_maindiv"
                    : "SOTemplate_maindivClose"
                }
              >
                <div className="templates-Header-Title"> {header}
                </div>

                <TabView
                  activeIndex={this.state.activeIndex}
                  onTabChange={(e) => this.tabOnChange(e)}
                >
                  {templateNames &&
                    templateNames.map((template, i) => {
                      return (
                        <TabPanel header={template.title}>
                          <div className="importTemplate-div-upload">

                            <div className="template-file-upload">
                              <center>
                                <Button
                                  label={
                                    TranslationFile.ImportTemplate[
                                    userSelectedLanguage
                                    ]
                                  }
                                  className="import-btn-upload"
                                  icon="pi pi-file-import"
                                  onClick={() =>
                                    this.setState({ visible: true })
                                  }
                                />


                                <Button
                                  severity="success"
                                  label={
                                    TranslationFile.ExportTemplate[
                                    userSelectedLanguage
                                    ]
                                  }
                                  onClick={(e) => {
                                    this.downloadTemplate(i, e)
                                  }}
                                  visible={
                                    templateLinesGrid.length > 0 ? true : false
                                  }
                                  className="templatePageDownloadBtn"
                                  icon="pi pi-download"
                                ></Button>

                                <Dialog
                                  header={
                                    TranslationFile.Importtemplatefile[
                                    userSelectedLanguage
                                    ]
                                  }
                                  visible={this.state.visible}
                                  onHide={() =>
                                    this.setState({ visible: false })
                                  }
                                  id="import-dialog"
                                  style={{ width: "50vw" }}
                                  onClick={this.dialogBoxOnClick}
                                >
                                  <FileUpload
                                    maxFileSize={10000000000}
                                    name="demo[]"
                                    accept=".xlsx"
                                    emptyTemplate={
                                      <p className="m-0">
                                        {
                                          TranslationFile
                                            .Draganddropfilestoheretoupload[
                                          userSelectedLanguage
                                          ]
                                        }
                                        {/* Drag and drop files to here to upload. */}
                                      </p>
                                    }
                                    onSelect={this.selectFile}
                                    multiple={false}
                                    onRemove={this.removeFile}
                                  />

                                  {showProceedButton != "" && (
                                    <div className="proceed-btn-div">
                                      <button onClick={this.test.bind(this, i)}>
                                        {
                                          TranslationFile.Proceed[
                                          userSelectedLanguage
                                          ]
                                        }
                                        {/* Proceed */}
                                      </button>
                                    </div>
                                  )}
                                </Dialog>
                              </center>
                            </div>
                            <div className="template-data-table-div">
                              <center>
                                <DataTable
                                  value={templateLinesGrid}
                                  dataKey="id"
                                  stripedRows
                                  tableStyle={{ minWidth: "50rem" }}
                                  className="template-data-table"
                                  paginator
                                  rows={10}
                                  editMode="row"
                                  rowsPerPageOptions={[5, 10, 25, 50]}
                                  onRowEditComplete={(e) =>
                                    this.onRowEditComplete(e, i)
                                  }
                                  onRowEditInit={this.onRowEditInit}
                                >
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field="no"
                                      header={
                                        TranslationFile.No[userSelectedLanguage]
                                      }
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field="validFromDate"
                                      header={
                                        TranslationFile.ValidFrom[
                                        userSelectedLanguage
                                        ]
                                      }
                                      body={this.fromDateString}
                                      editor={(options) =>
                                        this.validFromDateEditor(options)
                                      }
                                      filter
                                      className="templateValidFromTitle"
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field="validToDate"
                                      header={
                                        TranslationFile.ValidTo[
                                        userSelectedLanguage
                                        ]
                                      }
                                      body={this.toDateString}
                                      editor={(options) =>
                                        this.validToDateEditor(options)
                                      }
                                      filter
                                      className="templateValidToTitle"
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field="statementType"
                                      header={
                                        TranslationFile.StatementType[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.statementTypeEditor(options)
                                      }
                                      filter
                                    // body={this.roleBodyTemplate}
                                    ></Column>
                                  )}
                                  {i == 1 && (
                                    <Column
                                      field="statementSubType"
                                      header={
                                        TranslationFile.StatementSubType[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.statementSubTypeEditor(options)
                                      }
                                      filter
                                    // body={this.roleBodyTemplate}
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field="lineType"
                                      header={
                                        TranslationFile.LineType[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.lineTypeEditor(options)
                                      }
                                      filter
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field="lineLevel"
                                      header={
                                        TranslationFile.LineLevel[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.lineLevelEditor(options)
                                      }
                                      filter
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field="sumLineNos"
                                      header={
                                        TranslationFile.SumLineNos[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.sumLineNosEditor(options)
                                      }
                                      filter
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field="parentLineNo"
                                      header={
                                        TranslationFile.ParentLineNo[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.parentLineNoEditor(options)
                                      }
                                      filter
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field="lineNo"
                                      header={
                                        TranslationFile.lineNo[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.lineNoEditor(options)
                                      }
                                      filter
                                    ></Column>
                                  )}
                                  {/* 
                              {i == 0 && (
                                <Column
                                  field="mapToAccNos"
                                    header={
                                        TranslationFile.MappingNos[
                                          userSelectedLanguage
                                        ]
                                      }
                                  editor={(options) => this.textEditor(options)}
                                ></Column>
                              )} */}
                                  {(i == 0 || i == 1) && (
                                    // element.mapToAccountNos,
                                    // "@@555details",
                                    // element.skMappingRange
                                    <Column
                                      field={
                                        i == 0
                                          ? "mapToAccNos"
                                          : "mapToAccountNos"
                                      }
                                      header={
                                        TranslationFile.MapToAccountNo[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.mapToAccNosEditor(options)
                                      }
                                      filter
                                      className="templateSkMapTitle"
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field={
                                        i == 0
                                          ? "sKMappingRange"
                                          : "skMappingRange"
                                      }
                                      header={
                                        TranslationFile.SKMappingRange[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.sKMappingRangeEditor(options)
                                      }
                                      filter
                                      className="templateSkMapTitle"
                                    ></Column>
                                  )}
                                  {(i == 0 || i == 1) && (
                                    <Column
                                      field={i == 0 ? "accDesc" : "column1"}
                                      header={
                                        TranslationFile.AccountDescription[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.column1Editor(options)
                                      }
                                      filter
                                      className="templateAccDescTitle"
                                    ></Column>
                                  )}
                                  {i == 1 && (
                                    <Column
                                      field="column2"
                                      header={
                                        TranslationFile.column2[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.textEditor(options)
                                      }
                                      className="templateAccDescTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 1 && (
                                    <Column
                                      field="column3"
                                      header={
                                        TranslationFile.column3[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.textEditor(options)
                                      }
                                      className="templateAccDescTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 1 && (
                                    <Column
                                      field="column4"
                                      header={
                                        TranslationFile.column4[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.textEditor(options)
                                      }
                                      className="templateAccDescTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 1 && (
                                    <Column
                                      field="column5"
                                      header={
                                        TranslationFile.column5[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.textEditor(options)
                                      }
                                      className="templateAccDescTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 1 && (
                                    <Column
                                      field="column6"
                                      header={
                                        TranslationFile.column6[
                                        userSelectedLanguage
                                        ]
                                      }
                                      editor={(options) =>
                                        this.textEditor(options)
                                      }
                                      className="templateAccDescTitle"
                                      filter
                                    ></Column>
                                  )}

                                  {i == 2 && (
                                    <Column
                                      field="packageId"
                                      header={
                                        TranslationFile.PackageID[
                                        userSelectedLanguage
                                        ]
                                      }
                                      // body={this.fromDateString}
                                      // editor={(options) => this.validFromDateEditor(options)}
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 2 && (
                                    <Column
                                      field="customerType"
                                      header={
                                        TranslationFile.CustomerType[
                                        userSelectedLanguage
                                        ]
                                      }
                                      // body={this.fromDateString}
                                      editor={(options) =>
                                        this.customerTypeEditor(options)
                                      }
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 2 && (
                                    <Column
                                      field="maxUsage"
                                      header={
                                        TranslationFile.MaximumUsage[
                                        userSelectedLanguage
                                        ]
                                      }
                                      // body={this.fromDateString}
                                      editor={(options) =>
                                        this.maxUsageEditor(options)
                                      }
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 2 && (
                                    <Column
                                      field="packageName"
                                      header={
                                        TranslationFile.PackageName[
                                        userSelectedLanguage
                                        ]
                                      }
                                      // body={this.fromDateString}
                                      editor={(options) =>
                                        this.packageNameEditor(options)
                                      }
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 2 && (
                                    <Column
                                      field="price"
                                      header={
                                        TranslationFile.UnitPrice[
                                        userSelectedLanguage
                                        ]
                                      }
                                      // body={this.fromDateString}
                                      editor={(options) =>
                                        this.priceEditor(options)
                                      }
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 2 && (
                                    <Column
                                      field="validityPeriod"
                                      header={
                                        TranslationFile.ValidityPeriod[
                                        userSelectedLanguage
                                        ]
                                      }
                                      // body={this.fromDateString}
                                      editor={(options) =>
                                        this.validityPeriodEditor(options)
                                      }
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 2 && (
                                    <Column
                                      field="text1"
                                      header="Text 1"
                                      // body={this.fromDateString}
                                      editor={(options) =>
                                        this.text1Editor(options)
                                      }
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 2 && (
                                    <Column
                                      field="text2"
                                      header="Text 2"
                                      // body={this.fromDateString}
                                      editor={(options) =>
                                        this.text2Editor(options)
                                      }
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 2 && (
                                    <Column
                                      field="text3"
                                      header="Text 3"
                                      // body={this.fromDateString}
                                      editor={(options) =>
                                        this.text3Editor(options)
                                      }
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}
                                  {i == 2 && (
                                    <Column
                                      field="visibleInGrid"
                                      header={
                                        TranslationFile.visibleInGrid[
                                        userSelectedLanguage
                                        ]
                                      }
                                      body={this.visibleInGridBodyTemplate}
                                      editor={(options) =>
                                        this.visibleInGridEditor(options)
                                      }
                                      className="templateValidFromTitle"
                                      filter
                                    ></Column>
                                  )}

                                  <Column
                                    rowEditor
                                    headerStyle={{
                                      width: "5%",
                                      minWidth: "5rem",
                                    }}
                                    bodyStyle={{ textAlign: "center" }}
                                    frozen
                                    alignFrozen="right"
                                    className="template_edit_frozen"
                                  ></Column>
                                </DataTable>
                              </center>
                            </div>
                          </div>
                        </TabPanel>
                      );
                    })}
                </TabView>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, null)(Template);
